import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { CookiesProvider } from 'react-cookie'
import { Global } from '@emotion/react'
import style from './styles/global.style'
import './styles/theme.css'

//
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

// ---
root.render(
  <StrictMode>
    <Global styles={style} />
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
