import { css } from "@emotion/react"

const IpronMessages = {
  container: css`
    background-color: #ffffffaa;
    backdrop-filter: blur(4px);
    padding: 10px 14px;
    border-radius: 12px;
    box-shadow: 0px 3px 5px #00000022;
    margin-right: 10vw;
  `,
  message: css`
  `
}

//
export default IpronMessages